import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
// import template from '@/views/template.vue'
// import login from '@/views/login.vue'
// import myVideo from '@/views/myVideo.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'layoutView',
    component: layout,
    redirect: 'template',
    children: [
      {
        path: '/template',
        name: 'template',
        component: () => import('@/views/template.vue'),
      },
      {
        path: '/myVideo',
        name: 'myVideo',
        component: () => import('@/views/myVideo.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'loginView',
    component: () => import('@/views/login.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// //判断登录状态
router.beforeEach((to, from, next) => {
  console.log('to', to)
  console.log('from', from)
  // next({ path: '/login' })

  if (to.path === '/login') {
    next()
  } else if (localStorage.getItem('token')) {
    next()
  } else {
    next('/login')
  }

  // next();
}
)

export default router

import axios from "./axios"; //引入axios
import { Message } from 'element-ui';

const service = axios.create({

    settimeout: 10000, //超时时间
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // console.log("请求的数据:", config.url)
        // 在发送请求之前验证token
        if (config.url == "/api/user/loginByPhone") {
            return config;
        } else {
            if (localStorage.getItem("token")) {
                config.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
                // localStorage.removeItem("userInfo");
                //   console.log(localStorage.getItem("token"));
                //   console.log("存入的token",token);
                //   console.log("config请求的数据:", config.headers.token);
            }
            return config;
        }
    },
    (error) => {
        // 请求错误
        console.log("error请求的数据:", error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // console.log("返回的数据", response);
        // 这里拦截401错误，并重新跳入登页重新获取token
        // 后面还能继续判断code
        // console.log("返回的数据", response)

        if (response.status && response.status === 200) {
            //登录返回token
            if (response.config.url == "/api/user/loginByPhone") {
                if (response.data.code == 0) {
                    window.localStorage.setItem("token", response.data.data);

                }
            }
            // 通讯成功
            if (response.data.code === 0) {
                return response.data;
            } else if (response.data.code == 401 || response.data.code === 40200) {
                // 如果是token过期，跳转至登录
                // console.log("登录过期");
                // console.log("清除token");
                // localStorage.removeItem("token"); // 移除token，跳转至登录
                localStorage.clear();
                sessionStorage.clear();
                // alert("请重新登录!");
                Message({
                    showClose: true,
                    message: "登录无效，请重新登录",
                    type: 'warning'
                  });
            }else {
                console.log("其他错误");
                Message({
                    showClose: true,
                    message: "错误：" + response.data.message,
                    type: 'error'
                  });
                
            }
            return Promise.resolve(response.data);
        }
    },
    (error) => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default service;